// src/userPool.tsx => git에 올리지 않음
// 생성한 사용자 풀의 풀ID와 앱클라이언트ID를 넣어서 CongnitoUserPool을 생성

const userpoolData = {
  aws_project_region: 'ap-northeast-2',
  aws_cognito_region: 'ap-northeast-2',
  aws_user_pools_id: 'ap-northeast-2_YeiB5QBZM',
  aws_user_pools_web_client_id: '1a4ho1m8n5fthnalmhsraf5v6q',
};

export default userpoolData;
